/* eslint-disable react-hooks/exhaustive-deps */
import { useNavigate } from "react-router-dom";
import "./channelListSongSettings.scss";

import { ChevronLeft, ChevronRight, MoreVert } from "@mui/icons-material";
import { IconButton, MenuItem, Select, Tooltip, useTheme } from "@mui/material";
import MaterialReactTable, {
  MRT_ColumnDef,
  MRT_SortingState,
} from "material-react-table";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { useChannelIdContext } from "../../../context/ChannelIdContext";

const API_URL = process.env.REACT_APP_BACKEND_URL;
const ChannelListSongSettings: any = () => {
  const { t } = useTranslation();
  const column = useMemo<MRT_ColumnDef<any>[]>(
    () => [
      {
        accessorKey: "title",
        header: t("main.title"),
      },
      {
        accessorKey: "artist1Name",
        header: t("main.artist_1"),
      },
      {
        accessorKey: "artist2Name",
        header: t("main.artist_2"),
      },
      {
        accessorKey: "artist3Name",
        header: t("main.artist_3"),
      },
      {
        accessorKey: "releaseYear",
        header: t("main.release_year"),
      },
      {
        accessorKey: "commenceDate",
        header: t("main.commence_date"),
      },
      {
        accessorKey: "expireDate",
        header: t("main.expiry_date"),
      },
      {
        accessorKey: "priority",
        header: t("main.priority"),
      },
    ],
    [t]
  );

  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const {
    setSongId,
    channelId,
    searchInput,
    rowSelection,
    setIsLoading,
    currentUser,
    setRowSelection,
    songListByChannel,
    setSongListByChannel,
    setSelectedAudioEditor,
    setIsAddedSong,
    isAddedSong,
    setIsTokenExpired,
    setIsTokenUpdated,
    IsTokenUpdated,
    RegenerateChannelData,
    setRegenerateChannelData,
  } = useChannelIdContext();
  let theme = useTheme();
  const [totalCount, setTotalCount] = useState<number>(1);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [sorting, setSorting] = useState<MRT_SortingState>([]);
  const [pageSize] = useState<number | any>(localStorage.getItem("pageSize"));
  const [filteredData, setFilteredData] = useState<any>([]);
  let token = JSON.parse(localStorage.getItem("token") ?? "{}");
  const [tablePageSize, setTablePageSize] = useState(
    pageSize
      ? JSON.parse(pageSize).channelSongListTable
        ? JSON.parse(pageSize).channelSongListTable
        : 25
      : 25
  );

  const fetchSongListByChannel = useCallback(async () => {
    console.log("Fetching songs");
    setIsLoading(true);
    fetch(
      `${API_URL}/Channel/ChannelSongs/${channelId}?pageNumber=${currentPage}&pageSize=${tablePageSize}&searchText=${searchInput}&sortby=${
        sorting[0] ? sorting[0].id : ""
      }&orderBy=${sorting[0] ? sorting[0].desc : true}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `bearer ${token?.accessToken}`,
        },
      }
    )
      .then((response) => {
        if (
          response.ok === false &&
          response.status === 401 &&
          token?.refreshToken !== ""
        ) {
          setIsTokenExpired(true);
        } else if (response.status === 401 && token?.refreshToken === "") {
          navigate("/");
          localStorage.removeItem("token");
          localStorage.clear();
        } else {
          setIsTokenUpdated(false);
          return response.json();
        }
      })
      .then((data) => {
        if (data.success === true) {
          setRegenerateChannelData(false);
          setSongListByChannel(data?.data);
          setTotalCount(data?.pagination?.totalCount);
          setIsLoading(false);
          setIsAddedSong(false);
        } else {
          setIsLoading(false);
          setIsAddedSong(false);
          setRegenerateChannelData(false);
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    channelId,
    currentPage,
    tablePageSize,
    setIsLoading,
    setSongListByChannel,
    isAddedSong,
    sorting,
    IsTokenUpdated,
    RegenerateChannelData,
  ]);
  const handleForwardPage = () => {
    setCurrentPage(currentPage + 1);
  };

  const handlebackwordPage = () => {
    setCurrentPage(currentPage - 1);
  };

  useEffect(() => {
    fetchSongListByChannel();
  }, [isAddedSong]);

  const handleClick = (event: React.MouseEvent<HTMLElement>, row: any) => {
    if (!currentUser.editChannelPlaylist) {
      toast.warning(
        t("main.user_doesn't_have_authority_to_edit_channel_playlist")
      );
    } else {
      setSelectedAudioEditor("music");
      setAnchorEl(event.currentTarget);
      navigate(`audio-editor/${row.original.id}`);
    }
  };

  const FilterChannelSongData = useCallback(() => {
    const filteredList = songListByChannel?.filter((item: any) => {
      return item?.title?.toLowerCase()?.includes(searchInput?.toLowerCase());
    });
    setFilteredData(filteredList);
  }, [searchInput, songListByChannel]);

  useEffect(() => {
    if (rowSelection) {
      const arrOfIndex = Object.keys(rowSelection);
      const arrOfIds = songListByChannel
        ?.filter((_, i: number) => arrOfIndex?.includes(i.toString()))
        ?.map((e) => e.id);
      setSongId(arrOfIds);
    } else {
      setSongId([]);
    }
  }, [rowSelection, setSongId, songListByChannel, isAddedSong]);

  useEffect(() => {
    FilterChannelSongData();
  }, [FilterChannelSongData]);

  useEffect(() => {
    setRowSelection({});
  }, [setRowSelection, songListByChannel.length]);

  useEffect(() => {
    if (channelId) fetchSongListByChannel();
  }, [channelId, totalCount, currentPage, tablePageSize]);

  return (
    <>
      <div
        className={
          theme.palette.mode === "dark"
            ? "tableResponsive channelsonglistDark"
            : "tableResponsive channelsonglist"
        }
      >
        <MaterialReactTable
          columns={column}
          data={searchInput ? filteredData : songListByChannel}
          enablePagination={false}
          enableBottomToolbar={false}
          onRowSelectionChange={setRowSelection}
          onSortingChange={(col: MRT_SortingState | any) => {
            setSorting(col);
          }}
          state={{ rowSelection, sorting }}
          enableTopToolbar={false}
          enableRowSelection={true}
          enableRowActions={true}
          enableSorting={true}
          // manualFiltering={true}
          // manualSorting={true}
          enableColumnActions={true}
          enableColumnOrdering={true}
          enablePinning
          renderRowActions={({ row }) => (
            <Tooltip title="Audio editor">
              <IconButton
                onClick={(event) => {
                  handleClick(event, row);
                }}
                aria-label="more"
                id="long-button"
                aria-controls={open ? "long-menu" : undefined}
                aria-expanded={open ? "true" : undefined}
                aria-haspopup="true"
              >
                <MoreVert />
              </IconButton>
            </Tooltip>
          )}
        />
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            gap: "10px",
            border: "1px solid #ccc",
          }}
        >
          <span>{t("main.rows_per_page")}</span>
          <Select
            sx={{ height: "30px" }}
            value={tablePageSize || ""}
            onChange={(e) => {
              setTablePageSize(e.target.value);
              let tablePageSize = {
                ...JSON.parse(pageSize),
                channelSongListTable: e.target.value,
              };
              localStorage.setItem("pageSize", JSON.stringify(tablePageSize));
            }}
          >
            <MenuItem value="25">25</MenuItem>
            <MenuItem value="50">50</MenuItem>
            <MenuItem value="100">100</MenuItem>
          </Select>
          <span>
            {(currentPage - 1) * tablePageSize + 1} -{" "}
            {currentPage * tablePageSize < totalCount
              ? currentPage * tablePageSize
              : currentPage * tablePageSize -
                (currentPage * tablePageSize - totalCount)}{" "}
            of {totalCount}
          </span>
          <IconButton
            onClick={handlebackwordPage}
            disabled={(currentPage - 1) * tablePageSize + 1 <= 1}
          >
            <ChevronLeft />
          </IconButton>
          <IconButton
            onClick={handleForwardPage}
            disabled={currentPage * tablePageSize >= totalCount}
          >
            <ChevronRight />
          </IconButton>
        </div>
      </div>
    </>
  );
};
export default ChannelListSongSettings;
