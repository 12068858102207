/* eslint-disable react-hooks/exhaustive-deps */
import { Edit } from "@mui/icons-material";
import {
  Box,
  CircularProgress,
  IconButton,
  MenuItem,
  Select,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import MaterialReactTable, { MRT_ColumnDef } from "material-react-table";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useChannelIdContext } from "../../../context/ChannelIdContext";
import AddScheduleItemDialog from "../../Dialogs/AddScheduleItemDialog/AddScheduleItemDialog";
import "./ScheduleTable.scss";
const API_URL = process.env.REACT_APP_BACKEND_URL;

const ScheduleTable = () => {
  let token = JSON.parse(localStorage.getItem("token") ?? "{}");
  const { t } = useTranslation();
  const theme = useTheme();
  let navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [rowSelection, setRowSelection] = useState<any>();
  const [filteredData, setFilteredData] = useState([]);
  const [scheduleEditList, setScheduleEditList] = useState<any>({});
  const [customerSiteData, setCustomerSiteData] = useState<any[]>([]);
  const [selectedCustomerData, setSelectedCustomerData] = useState("");
  const [selectedCustSiteId, setSelectedCustSiteId] = useState<any>([]);
  const { setSelectedCustomer, setSelectedSite } = useChannelIdContext();
  const {
    isAdded,
    isDeleted,
    setIsAdded,
    searchInput,
    currentUser,
    setisDeleted,
    searchContent,
    setScheduleId,
    scheduleList,
    setScheduleList,
    setIsTokenExpired,
    setIsTokenUpdated,
    IsTokenUpdated,
  } = useChannelIdContext();
  const [, setGroupedColumnId] = useState<string[]>([]);

  const column = useMemo<MRT_ColumnDef<any>[]>(
    () => [
      {
        accessorKey: "zone.name",
        header: t("main.zone"),
      },
      {
        accessorKey: "dayOfWeek",
        header: t("main.day"),
      },
      {
        accessorKey: "startingTime",
        header: t("main.start_time"),
      },
      {
        accessorKey: "channel.name",
        header: t("main.channel"),
      },
      {
        accessorKey: "voiceWheel.name",
        header: t("main.wheel"),
      },
      {
        accessorKey: "dropIn.name",
        header: t("main.dropIn"),
      },
    ],
    [t]
  );

  const generateDayOfWeek = (weeknum: any) => {
    let dayOfWeek;
    switch (weeknum) {
      case 1:
        dayOfWeek = "Monday";
        break;
      case 2:
        dayOfWeek = "Tuesday";
        break;
      case 3:
        dayOfWeek = "Wednesday";
        break;
      case 4:
        dayOfWeek = "Thursday";
        break;
      case 5:
        dayOfWeek = "Friday";
        break;
      case 6:
        dayOfWeek = "Saturday";
        break;
      case 0:
        dayOfWeek = "Sunday";
        break;
    }
    return dayOfWeek;
  };

  const getCustomerSites = useCallback(async (id: number) => {
    const response = await fetch(`${API_URL}/Site/Get/` + id, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `bearer ${token?.accessToken}`,
      },
    });
    const data = await response.json();
    return data?.data;
  }, []);

  const fetchAllCustomer = useCallback(async () => {
    const response = await fetch(`${API_URL}/Customer/GetAll`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `bearer ${token?.accessToken}`,
      },
    });
    const data = await response.json();
    let temp = [];
    for (let index = 0; index < data?.data?.length; index++) {
      const element = data?.data[index];
      let returnData = await getCustomerSites(element.id);
      if (returnData?.length > 0) {
        for (let index = 0; index < returnData?.length; index++) {
          const siteElement = returnData[index];
          temp.push({
            id: element?.id,
            name: element?.name + "/" + siteElement?.name,
            value: [element?.id, siteElement?.id],
          });
        }
      }
    }
    setCustomerSiteData(temp);
  }, [getCustomerSites]);

  const handleClose = () => {
    setIsOpen(false);
  };

  const fetchScheduleList = useCallback(async () => {
    const response = await fetch(`${API_URL}/Schedule/GetAll`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `bearer ${token?.accessToken}`,
      },
    });
    const data = await response.json();
    for (let index = 0; index < data?.data?.length; index++) {
      const element = data?.data[index];
      let resultOfWeek = generateDayOfWeek(element?.dayOfWeek);
      element.dayOfWeek = resultOfWeek;
    }
    if (searchInput === "") {
      if (data.data === null) {
        setScheduleList({ loaded: true, schedules: [] });
      } else {
        setScheduleList({ loaded: true, schedules: data?.data });
      }
    } else {
      setScheduleList((scheduleList: any) => {
        const FilterData = scheduleList?.schedules?.filter((row: any) =>
          row?.startTime?.toLowerCase().includes(searchInput.toLowerCase())
        );
        return { loaded: true, schedules: FilterData };
      });
    }
  }, [searchInput, setScheduleList]);

  const FilterScheduleData = useCallback(() => {
    const filteredList = scheduleList?.schedules?.filter((item: any) => {
      return item?.zone?.name
        .toLowerCase()
        .includes(searchContent.toLowerCase());
    });
    setFilteredData(filteredList);
  }, [scheduleList?.schedules, searchContent]);
  useEffect(() => {
    fetchScheduleList();
  }, [fetchScheduleList]);

  useEffect(() => {
    fetchAllCustomer();
  }, [fetchAllCustomer]);

  useEffect(() => {
    FilterScheduleData();
  }, [FilterScheduleData, searchContent]);

  /**
   * filter schedule based on customer/sites
   */
  useEffect(() => {
    if (selectedCustSiteId.length > 0) {
      const fetchFilteredScheduleList = async () => {
        fetch(
          `${API_URL}/Schedule/GetByFilters?customerId=${selectedCustSiteId[0]}&siteId=${selectedCustSiteId[1]}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `bearer ${token?.accessToken}`,
            },
          }
        )
          .then((response) => {
            if (
              response.ok === false &&
              response.status === 401 &&
              token?.refreshToken !== ""
            ) {
              setIsTokenExpired(true);
            } else if (response.status === 401 && token?.refreshToken === "") {
              navigate("/");
              localStorage.removeItem("token");
              localStorage.clear();
            } else {
              setIsTokenUpdated(false);
              return response.json();
            }
          })
          .then((data) => {
            if (data.success === true) {
              setIsAdded(false);
              setisDeleted(false);
              setScheduleList({ loaded: true, schedules: data?.data });
              for (let index = 0; index < data?.data?.length; index++) {
                const element = data?.data[index];
                let resultOfWeek = generateDayOfWeek(element?.dayOfWeek);
                element.dayOfWeek = resultOfWeek;
              }
            } else {
              setScheduleList({ loaded: true, schedules: [] });
            }
          });
      };
      fetchFilteredScheduleList();
    }
  }, [
    selectedCustSiteId,
    setIsAdded,
    setScheduleList,
    setisDeleted,
    isAdded,
    isDeleted,
    IsTokenUpdated,
  ]);

  return scheduleList.loaded === false ? (
    <div style={{ justifyContent: "center", display: "flex" }}>
      <CircularProgress style={{ marginTop: "15rem" }} />
    </div>
  ) : (
    <div>
      <div
        className="content-filter"
        style={{ display: "flex", justifyContent: "space-between" }}
      >
        <Select
          displayEmpty
          value={
            customerSiteData?.some((item) => item.name === selectedCustomerData)
              ? selectedCustomerData
              : ""
          }
          renderValue={(value) => {
            if (value) {
              return customerSiteData?.find(
                (item) => item.name === selectedCustomerData
              )?.name;
            } else {
              return <Typography>{t("main.select_customer")}</Typography>;
            }
          }}
          onChange={(e: any) => {
            setSelectedCustomerData(e.target.value);
            const CustomersId = customerSiteData?.find(
              (item) => item.name === e.target.value
            ).value;
            setSelectedCustSiteId(CustomersId);
          }}
        >
          {customerSiteData &&
            customerSiteData?.map((item: any) => (
              <MenuItem
                key={item?.name}
                value={item?.name}
                onClick={() => {
                  setSelectedCustomer(item?.id);
                  setSelectedSite(item?.value[1]);
                }}
              >
                {item?.name}
              </MenuItem>
            ))}
        </Select>
      </div>
      {/* <div className={theme.palette.mode === "dark" ? "tableResponsive listTable3" : "tableResponsive listTable"}> */}
      <MaterialReactTable
        columns={column}
        data={
          selectedCustomerData
            ? searchContent?.length > 0
              ? filteredData
              : scheduleList?.schedules
            : []
        }
        enableGrouping={true}
        enablePagination={false}
        enableRowActions
        enableColumnActions={true}
        enableColumnOrdering={true}
        getRowId={(row) => row.id}
        defaultColumn={{
          maxSize: 400,
          minSize: 80,
          size: 150, //default size is usually 180
        }}
        muiTableBodyCellProps={({ row }) => ({
          sx: {
            paddingTop: 0,
            paddingBottom: 0,
          },
          style: {
            backgroundColor:
              row.original.id === rowSelection ? "#42876A" : "#FFF",
          },
        })}
        renderRowActions={({ row }) => (
          <Box className="music-library-table-box">
            <Tooltip title="Audio editor">
              <IconButton
                onClick={() =>
                  currentUser?.editSchedules
                    ? (setIsOpen(true),
                      setGroupedColumnId(row.original.id),
                      setScheduleEditList(row.original))
                    : toast.error(
                        t("main.User_doesn't_have_authority_to_edit_schedule")
                      )
                }
              >
                <Edit />
              </IconButton>
            </Tooltip>
          </Box>
        )}
        enablePinning
        enableColumnResizing
        enableToolbarInternalActions={false}
        columnResizeMode="onChange"
        initialState={{
          // expanded: true,
          grouping: ["zone.name", "dayOfWeek"],
        }}
        muiTableBodyRowProps={({ row }) => ({
          onClick: () => {
            setRowSelection(row.id);
            setScheduleId(row.id);
          },
          selected: rowSelection === row.id,
          sx: {
            backgroundColor: "#E3EAE4",
            cursor: "pointer",
          },
        })}
      />
      {/* </div> */}
      {isOpen ? (
        <AddScheduleItemDialog
          isOpen={true}
          scheduleEditList={scheduleEditList}
          isEdit={true}
          handleClose={handleClose}
        ></AddScheduleItemDialog>
      ) : (
        ""
      )}
    </div>
  );
};

export default ScheduleTable;
