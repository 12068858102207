/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import {
  ChevronLeft,
  ChevronRight,
  Edit,
  FirstPage,
  LastPage,
  Search,
} from "@mui/icons-material";
import {
  Box,
  Button,
  IconButton,
  InputAdornment,
  MenuItem,
  OutlinedInput,
  Select,
  Tooltip,
  useTheme,
} from "@mui/material";
import MaterialReactTable, {
  MRT_ColumnDef,
  MRT_SortingState,
} from "material-react-table";
import moment from "moment";
import React, {
  FC,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useChannelIdContext } from "../../../context/ChannelIdContext";
import { songList } from "../../../types/Audio";
import DeleteSongDialog from "../../Dialogs/DeleteSongDialog/DeleteSongDialog";
import "./trackTable.scss";
const API_URL = process.env.REACT_APP_BACKEND_URL;

const TrackTable: FC = () => {
  const [songs, setSongs] = useState<{
    loaded: boolean;
    songList: songList[];
  }>({ loaded: false, songList: [] });

  const [filterSongs, setFilterSongs] = useState<{
    loaded: boolean;
    songList: songList[];
  }>({ loaded: false, songList: [] });

  const navigate = useNavigate();
  let token = JSON.parse(localStorage.getItem("token") ?? "{}");
  const {
    songId,
    setSongId,
    isLoading,
    setSelectedAudioEditor,
    setIsLoading,
    importXMLFile,
    currentUser,
    setImportXMLFile,
    setIsTokenExpired,
    setIsTokenUpdated,
    IsTokenUpdated,
  } = useChannelIdContext();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const formatDate = (value: any) => {
    const date = moment(value?.renderedCellValue).format("DD-MM-YYYY");
    return `${date}`;
  };
  const { t } = useTranslation();
  const delay = useRef();
  const theme = useTheme();
  const [filter, setFilter] = useState<string>("");
  const [totalCount, setTotalCount] = useState<number>(1);
  const [rowSelection, setRowSelection] = useState<any>({});
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [searchInput, setSearchInput] = useState<string>("");
  const [searchInputText, setSearchInputText] = useState<string>("");
  const [allAttributes, setAllAttributes] = useState<any>([]);
  const [multipleAttr, setMultipleAttr] = useState<string[]>([]);
  const [sorting, setSorting] = useState<MRT_SortingState>([]);

  const [confirmation, setConfirmation] = useState<boolean>(false);
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [pageSize] = useState<number | any>(localStorage.getItem("pageSize"));
  const [tablePageSize, setTablePageSize] = useState(
    pageSize
      ? JSON.parse(pageSize).trackTable
        ? JSON.parse(pageSize).trackTable
        : 25
      : 25
  );

  const columns = useMemo<MRT_ColumnDef<songList>[]>(
    () => [
      {
        accessorKey: "title",
        header: t("main.title"),
      },
      {
        accessorKey: "artist1Name",
        header: t("main.artist_1"),
      },
      {
        accessorKey: "artist2Name",
        header: t("main.artist_2"),
      },
      {
        accessorKey: "artist3Name",
        header: t("main.artist_3"),
      },
      {
        accessorKey: "attributes",
        header: "Genre",
        Cell: ({ cell }) => {
          const row: any = cell.getValue();
          let output: string = "";

          for (let i = 0; i < row.length; i++) {
            if (row[i].categoryId === 1) {
              output += row[i].name + " ";
            }
          }

          return <span>{output}</span>;
        },
      },
      {
        accessorKey: "attributes",
        header: "Tempo",
        Cell: ({ cell }) => {
          const row: any = cell.getValue();
          let output: string = "";

          for (let i = 0; i < row.length; i++) {
            if (row[i].categoryId === 2) {
              output += row[i].name + " ";
            }
          }

          return <span>{output}</span>;
        },
      },
      {
        accessorKey: "attributes",
        header: "Decade",
        Cell: ({ cell }) => {
          const row: any = cell.getValue();
          let output: string = "";

          for (let i = 0; i < row.length; i++) {
            if (row[i].categoryId === 3) {
              output += row[i].name + " ";
            }
          }

          return <span>{output}</span>;
        },
      },
      {
        accessorKey: "attributes",
        header: "Gender",
        Cell: ({ cell }) => {
          const row: any = cell.getValue();
          let output: string = "";

          for (let i = 0; i < row.length; i++) {
            if (row[i].categoryId === 4) {
              output += row[i].name + " ";
            }
          }

          return <span>{output}</span>;
        },
      },
      {
        accessorKey: "releaseYear",
        header: t("main.release_year"),
      },
      {
        accessorKey: "commenceDate",
        header: t("main.commence_date"),
        Cell: (renderedCellValue: any) => (
          <span>{formatDate(renderedCellValue)}</span>
        ),
      },
      {
        accessorKey: "expireDate",
        header: t("main.expiry_date"),
        Cell: (renderedCellValue: any) => (
          <span>{formatDate(renderedCellValue)}</span>
        ),
      },
      {
        accessorKey: "priority",
        header: t("main.priority"),
      },
      {
        accessorKey: "fileName",
        header: t("main.fileName"),
      },
      {
        accessorKey: "creationDate",
        header: t("main.creation_date"),
        Cell: (renderedCellValue: any) => (
          <span>{formatDate(renderedCellValue)}</span>
        ),
      },
    ],
    [t]
  );

  const fetchData = useCallback(async () => {
    setIsLoading(true);
    console.log("Fetching");
    try {
      fetch(
        `${API_URL}/Song/GetAllWithPagination?pageNumber=${currentPage}&pageSize=${tablePageSize}&searchText=${searchInput}&sortby=${
          sorting[0] ? sorting[0].id : ""
        }&orderBy=${sorting[0] ? sorting[0].desc : true}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `bearer ${token?.accessToken}`,
          },
        }
      )
        .then((response) => {
          if (
            response.ok === false &&
            response.status === 401 &&
            token?.refreshToken !== ""
          ) {
            setIsTokenExpired(true);
          } else if (response.status === 401 && token?.refreshToken === "") {
            navigate("/");
            localStorage.removeItem("token");
            localStorage.clear();
          } else {
            setIsTokenUpdated(false);
            return response.json();
          }
        })
        .then((data) => {
          if (data.success === true) {
            setIsLoading(false);
            setImportXMLFile(false);
            setTotalCount(data?.pagination?.totalCount);
            setCurrentPage(data?.pagination?.currentPage);

            setSongs({ loaded: true, songList: data?.data });
            setFilterSongs({ loaded: true, songList: data?.data });
          } else {
            setIsLoading(false);
            setSongs({ loaded: true, songList: [] });
            setTotalCount(data?.pagination?.totalCount);
            setCurrentPage(data?.pagination?.currentPage);
            setFilterSongs({ loaded: true, songList: [] });
          }
        });
    } catch (error) {
      setIsLoading(false);
    }
  }, [
    sorting,
    currentPage,
    searchInput,
    setIsLoading,
    tablePageSize,
    importXMLFile,
    IsTokenUpdated,
  ]);

  const handleEmptyAudioFiles = () => {
    let emptyFiles: any = songs?.songList?.filter(
      (item: any) => item?.coverImageFile === ""
    );
    setFilterSongs({ loaded: false, songList: emptyFiles });
  };

  const handleChangedAudioFiles = () => {
    let changedFiles: any = songs?.songList
      ?.filter((item: any) => item?.updateDate !== null)
      ?.sort(
        (a: any, b: any) =>
          new Date(b?.updateDate)?.getTime() -
          new Date(a?.updateDate)?.getTime()
      );
    setFilterSongs({ loaded: false, songList: changedFiles });
  };

  const handleDelete = async () => {
    fetch(`${API_URL}/Song`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `bearer ${token?.accessToken}`,
      },
      body: JSON.stringify(songId),
    })
      .then((response) => {
        if (
          response.ok === false &&
          response.status === 401 &&
          token?.refreshToken !== ""
        ) {
          setIsTokenExpired(true);
        } else if (response.status === 401 && token?.refreshToken === "") {
          navigate("/");
          localStorage.removeItem("token");
          localStorage.clear();
        } else {
          setIsTokenUpdated(false);
          return response.json();
        }
      })
      .then((data) => {
        if (data?.success) {
          fetchData();
          setSongId([]);
          setRowSelection({});
          toast.success(t("main.Song_Deleted_Successfully"));
        }
      });
  };

  useEffect(() => {
    if (confirmation) {
      handleDelete();
      setConfirmation(false);
    }
  }, [confirmation]);

  const handleSynchronize = () => {
    fetch(`${API_URL}/Song/Synchronize`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `bearer ${token?.accessToken}`,
      },
    })
      .then((response) => {
        if (
          response.ok === false &&
          response.status === 401 &&
          token?.refreshToken !== ""
        ) {
          setIsTokenExpired(true);
        } else if (response.status === 401 && token?.refreshToken === "") {
          navigate("/");
          localStorage.removeItem("token");
          localStorage.clear();
        } else {
          setIsTokenUpdated(false);
          return response.json();
        }
      })
      .then((data) => {
        if (data?.success) {
          toast.success("Synchronize succesful");
        }
      });
  };

  const handleClick = (event: React.MouseEvent<HTMLElement>, row: any) => {
    if (!currentUser.editSongs) {
      toast.warning(t("main.User_have_no_authority_to_edit_song"));
    } else {
      setSelectedAudioEditor("music");
      setAnchorEl(event?.currentTarget);
      navigate(`audio-editor/${row?.original?.id}`);
    }
  };

  const handleLastPage = () => {
    if (
      totalCount / tablePageSize >
      parseInt((totalCount / tablePageSize).toString())
    ) {
      setCurrentPage(parseInt((totalCount / tablePageSize + 1).toString()));
    } else {
      setCurrentPage(parseInt((totalCount / tablePageSize).toString()));
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setSearchInputText(event?.target?.value);
    clearTimeout(delay?.current);
    delay.current = setTimeout(
      () => setSearchInput(event?.target?.value),
      500
    ) as any;
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  //useEffect Area
  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    if (rowSelection) {
      const arrOfIndex = Object.keys(rowSelection);
      const arrOfIds = songs?.songList
        ?.filter((_, i: number) => arrOfIndex?.includes(i?.toString()))
        ?.map((e) => e.id);
      setSongId(arrOfIds);
    } else {
      setSongId([]);
    }
  }, [rowSelection, setSongId, songs?.songList]);

  useEffect(() => {
    const fetchAttributeData = async () => {
      fetch(`${API_URL}/Attribute/GetAll`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `bearer ${token?.accessToken}`,
        },
      })
        .then((response) => {
          if (
            response.ok === false &&
            response.status === 401 &&
            token?.refreshToken !== ""
          ) {
            setIsTokenExpired(true);
          } else if (response.status === 401 && token?.refreshToken === "") {
            navigate("/");
            localStorage.removeItem("token");
            localStorage.clear();
          } else {
            setIsTokenUpdated(false);
            return response.json();
          }
        })
        .then((data) => {
          if (data.success === true) {
            setAllAttributes(data?.data);
          } else {
            setAllAttributes([]);
          }
        });
    };
    fetchAttributeData();
  }, [IsTokenUpdated]);

  useEffect(() => {
    let filterData: any = songs?.songList?.filter(
      (item) =>
        item?.attributes?.filter((x: any) => multipleAttr?.includes(x.id))
          ?.length === multipleAttr?.length
    );
    setFilterSongs({ loaded: false, songList: filterData });
  }, [multipleAttr, songs?.songList]);

  return (
    <>
      <div className="content-filter">
        <OutlinedInput
          autoComplete="off"
          size="small"
          autoFocus
          placeholder={t("main.title/artist...")}
          onChange={handleChange}
          endAdornment={
            <InputAdornment position="end">
              <IconButton>
                <Search />
              </IconButton>
            </InputAdornment>
          }
          value={searchInputText}
        />
        <Select
          multiple={true}
          value={multipleAttr}
          displayEmpty
          renderValue={
            multipleAttr?.length !== 0
              ? undefined
              : () => <>{t("main.attributes")}</>
          }
          onChange={({ target: { value } }: any) => {
            setMultipleAttr(value);
          }}
          onClick={handleClose}
        >
          {allAttributes?.length > 0 &&
            allAttributes?.map((item: any) => (
              <MenuItem key={item?.id} value={item?.id}>
                {item?.name}
              </MenuItem>
            ))}
        </Select>
        <Select
          displayEmpty
          value={filter}
          renderValue={
            filter !== "" ? undefined : () => <>{t("main.filter_by")}</>
          }
          onChange={({ target: { value } }: any) => {
            setFilter(value);
          }}
        >
          <MenuItem value="0" onClick={handleEmptyAudioFiles}>
            {t("main.empty_audio_file")}
          </MenuItem>
          <MenuItem value="1" onClick={handleChangedAudioFiles}>
            {t("main.changed_audio_files")}
          </MenuItem>
        </Select>
        <Button
          className="Button"
          onClick={() => {
            setFilter("");
            setMultipleAttr([]);
            setFilterSongs(songs);
            setSearchInput("");
            setSearchInputText("");
          }}
        >
          {t("main.clear_filter")}
        </Button>
        <Button
          className="Button"
          onClick={() => {
            handleSynchronize();
          }}
        >
          Synchronize
        </Button>
        {songId?.length > 0 ? (
          <Button className="Button" onClick={() => setDialogOpen(true)}>
            {"Delete"}
          </Button>
        ) : (
          ""
        )}
      </div>
      {/* <div
        className={
          theme.palette.mode === "dark"
            ? "tableResponsive listTable2"
            : "tableResponsive listTable"
        }
      > */}
      <MaterialReactTable
        columns={columns}
        data={
          multipleAttr?.length > 0 || filter
            ? filterSongs?.songList
            : songs?.songList
        }
        enableRowActions={true}
        enableStickyHeader
        enableRowSelection={true}
        onRowSelectionChange={setRowSelection}
        state={{
          rowSelection,
          isLoading: songs?.loaded === false || isLoading === true,
          sorting,
        }}
        enableColumnOrdering={true}
        enablePagination={false}
        manualFiltering={true}
        manualSorting={true}
        onSortingChange={(col: MRT_SortingState | any) => {
          setSorting(col);
        }}
        muiTableBodyCellProps={{
          sx: {
            paddingTop: 0,
            paddingBottom: 0,
          },
        }}
        enableBottomToolbar={false}
        enableSorting={true}
        enableTopToolbar={false}
        muiTablePaperProps={{
          elevation: 0,
          sx: {
            "& .css-sq9qdz": {
              justifyContent: "flex-start",
              padding: 0,
            },
            "& .css-1cjiko4": {
              display: "none",
            },
          },
        }}
        enablePinning
        muiTableBodyRowProps={({ row }: any) => ({
          sx: () => ({
            backgroundColor: row.original.isFinished
              ? "#E3EAE4"
              : "transparent",
          }),
          hover: false,
        })}
        renderRowActions={({ row }) => (
          <Box className="music-library-table-box">
            <Tooltip title="Audio editor">
              <IconButton
                onClick={(event) => {
                  handleClick(event, row);
                }}
                aria-label="more"
                id="long-button"
                aria-controls={open ? "long-menu" : undefined}
                aria-expanded={open ? "true" : undefined}
                aria-haspopup="true"
                style={{ color: "#000", margin: "0px", padding: "0px" }}
              >
                <Edit />
              </IconButton>
            </Tooltip>
          </Box>
        )}
      ></MaterialReactTable>
      {/* </div> */}
      <div className="customPagination">
        <span
          style={{ color: theme.palette.mode === "dark" ? "#fff" : "#000" }}
        >
          {t("main.rows_per_page")}
        </span>
        <Select
          sx={{ height: "30px" }}
          value={tablePageSize}
          onChange={(e) => {
            setTablePageSize(e.target.value);
            let tablePageSize = {
              ...JSON.parse(pageSize),
              trackTable: e.target.value,
            };
            localStorage.setItem("pageSize", JSON.stringify(tablePageSize));
          }}
        >
          <MenuItem value="25">25</MenuItem>
          <MenuItem value="50">50</MenuItem>
          <MenuItem value="100">100</MenuItem>
        </Select>
        <span
          style={{ color: theme.palette.mode === "dark" ? "#fff" : "#000" }}
        >
          {(currentPage - 1) * tablePageSize + 1} -{" "}
          {currentPage * tablePageSize < totalCount
            ? currentPage * tablePageSize
            : currentPage * tablePageSize -
              (currentPage * tablePageSize - totalCount)}{" "}
          of {totalCount}
        </span>
        <Box className="customPagination__buttons">
          <IconButton
            onClick={() => setCurrentPage(1)}
            disabled={(currentPage - 1) * tablePageSize + 1 <= 1}
          >
            <FirstPage />
          </IconButton>
          <IconButton
            onClick={() => setCurrentPage(currentPage - 1)}
            disabled={(currentPage - 1) * tablePageSize + 1 <= 1}
          >
            <ChevronLeft />
          </IconButton>
          <IconButton
            onClick={() => setCurrentPage(currentPage + 1)}
            disabled={currentPage * tablePageSize >= totalCount}
          >
            <ChevronRight />
          </IconButton>
          <IconButton
            onClick={handleLastPage}
            disabled={currentPage * tablePageSize >= totalCount}
          >
            <LastPage />
          </IconButton>
        </Box>
      </div>

      <DeleteSongDialog
        isOpen={dialogOpen}
        handleClose={() => setDialogOpen(false)}
        setConfirmation={setConfirmation}
      ></DeleteSongDialog>
    </>
  );
};

export default TrackTable;
