/* eslint-disable react-hooks/exhaustive-deps */
import CloseIcon from "@mui/icons-material/Close";
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  Link,
  Paper,
  PaperProps,
  TextField,
  Typography,
} from "@mui/material";
import React, { MouseEventHandler, useEffect, useState } from "react";
import Draggable from "react-draggable";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useChannelIdContext } from "../../../context/ChannelIdContext";
import { addCustomer } from "../../../types/Customer";
import "./addEditCustomerDialog.scss";

const API_URL = process.env.REACT_APP_BACKEND_URL;
function PaperComponent(props: PaperProps) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper
        {...props}
        style={{
          backgroundColor: "#34393f",
          color: "#D9D9D9",
          padding: "1rem",
        }}
      />
    </Draggable>
  );
}
export default function AddEditCustomerDialog({
  isOpen,
  isAdded,
  customerId,
  setIsAdded,
  handleClose,
}: {
  isOpen: boolean;
  isAdded: boolean;
  customerId: number | null;
  handleClose: any | MouseEventHandler;
  setIsAdded: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const { t } = useTranslation();
  let token = JSON.parse(localStorage.getItem("token") ?? "{}");
  const [isDisable, setIsDisable] = useState<boolean>(false);
  const [addCustomer, setAddCustomer] = useState<addCustomer>({
    name: "",
    address1: "",
    address2: "",
    address3: "",
    country: "",
    zipcode: "",
    phoneNumber1: "",
    phoneNumber2: "",
    phoneNumber3: "",
    contactName: "",
  });
  const navigate = useNavigate();
  const { setIsTokenExpired, setIsTokenUpdated, IsTokenUpdated } =
    useChannelIdContext();
  const HandleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setAddCustomer((prevAddCustomer: any) => ({
      ...prevAddCustomer,
      [name]: value,
    }));
  };

  const AddNewCustomer = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault();
    if (addCustomer) {
      const payload = customerId
        ? JSON.stringify({
            id: customerId,
            name: addCustomer?.name,
            zipcode: addCustomer?.zipcode,
            country: addCustomer?.country,
            address1: addCustomer?.address1,
            address2: addCustomer?.address2,
            address3: addCustomer?.address3,
            contactName: addCustomer?.contactName,
            phoneNumber1: addCustomer?.phoneNumber1,
            phoneNumber2: addCustomer?.phoneNumber2,
            phoneNumber3: addCustomer?.phoneNumber3,
          })
        : JSON.stringify(addCustomer);

      const requestOptions = {
        method: customerId ? "PUT" : "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `bearer ${token?.accessToken}`,
        },
        body: payload,
      };
      try {
        fetch(`${API_URL}/Customer`, requestOptions)
          .then((response) => {
            if (
              response.ok === false &&
              response.status === 401 &&
              token?.refreshToken !== ""
            ) {
              setIsTokenExpired(true);
            } else if (response.status === 401 && token?.refreshToken === "") {
              navigate("/");
              localStorage.removeItem("token");
              localStorage.clear();
            } else {
              setIsTokenUpdated(false);
              return response.json();
            }
          })
          .then((data) => {
            if (data?.success === true) {
              setIsAdded(true);
              handleClose();
              toast.success(
                customerId
                  ? t("main.Customer_Updated_Successfully")
                  : t("main.New_Customer_Added_Successfully")
              );
            } else {
              toast.error(data?.message);
            }
          });
      } catch (error) {
        toast.error(t("main.Failed_to_Save_Customer_Data"));
      }
    }
  };

  useEffect(() => {
    const fetchCustomer = async () => {
      try {
        const requestOptions = {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `bearer ${token?.accessToken}`,
          },
        };
        fetch(`${API_URL}/Customer/${customerId}`, requestOptions)
          .then((response) => {
            if (
              response.ok === false &&
              response.status === 401 &&
              token?.refreshToken !== ""
            ) {
              setIsTokenExpired(true);
            } else if (response.status === 401 && token?.refreshToken === "") {
              navigate("/");
              localStorage.removeItem("token");
              localStorage.clear();
            } else {
              setIsTokenUpdated(false);
              return response.json();
            }
          })
          .then((data) => {
            if (data?.success === true) {
              setAddCustomer(data?.data);
            } else {
              toast.error(data?.message);
            }
          });
      } catch (error) {
        toast.error(t("main.Failed_to_Fetch_Customer_Data"));
      }
    };
    if (customerId) {
      fetchCustomer();
    }
  }, [customerId, IsTokenUpdated]);

  useEffect(() => {
    if (
      addCustomer?.name !== "" &&
      addCustomer?.address1 !== "" &&
      // addCustomer?.country !== "" &&
      // addCustomer?.zipcode !== "" &&
      addCustomer?.phoneNumber1 !== "" &&
      addCustomer?.contactName !== ""
    ) {
      setIsDisable(false);
    } else {
      setIsDisable(true);
    }
  }, [
    addCustomer?.address1,
    addCustomer?.contactName,
    // addCustomer?.country,
    addCustomer?.name,
    addCustomer?.phoneNumber1,
    // addCustomer?.zipcode,
  ]);

  useEffect(() => {
    if (customerId === null || isAdded === true) {
      setAddCustomer({
        name: "",
        zipcode: "",
        country: "",
        address1: "",
        address2: "",
        address3: "",
        contactName: "",
        phoneNumber1: "",
        phoneNumber2: "",
        phoneNumber3: "",
      });
    }
  }, [customerId, isAdded]);

  return (
    <Dialog
      fullWidth
      open={isOpen}
      maxWidth="sm"
      onClose={handleClose}
      PaperComponent={PaperComponent}
      className="addCustomerModelDialog"
      aria-labelledby="draggable-dialog-title"
    >
      <DialogTitle>
        <Typography variant="h5" className="addModel-title">
          {customerId ? t("main.Edit_Customer") : t("main.Add_New_Customer")}
          <Link onClick={handleClose} className="modelCloseBtn">
            <CloseIcon />
          </Link>
        </Typography>
      </DialogTitle>
      <div className="dialogForm">
        <form>
          <div className="dialogForm-field addchanelFields">
            <Typography variant="h5" className="dialogForm-field-label">
              {t("main.name")}
            </Typography>
            <TextField
              required
              autoComplete="off"
              name="name"
              value={addCustomer ? addCustomer?.name : ""}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                HandleChange(e)
              }
            />
          </div>

          <div className="dialogForm-field addchanelFields">
            <Typography variant="h5" className="dialogForm-field-label">
              {t("main.Address")} 1
            </Typography>
            <TextField
              required
              autoComplete="off"
              name="address1"
              value={addCustomer ? addCustomer?.address1 : ""}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                HandleChange(e)
              }
            />
          </div>
          <div className="dialogForm-field addchanelFields">
            <Typography variant="h5" className="dialogForm-field-label">
              {t("main.Address")} 2
            </Typography>
            <TextField
              required
              autoComplete="off"
              name="address2"
              value={addCustomer ? addCustomer?.address2 : ""}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                HandleChange(e)
              }
            />
          </div>
          <div className="dialogForm-field addchanelFields">
            <Typography variant="h5" className="dialogForm-field-label">
              {t("main.Address")} 3
            </Typography>
            <TextField
              required
              autoComplete="off"
              name="address3"
              value={addCustomer ? addCustomer?.address3 : ""}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                HandleChange(e)
              }
            />
          </div>
          <div className="dialogForm-field addchanelFields">
            <Typography variant="h5" className="dialogForm-field-label">
              {t("main.Country")}
            </Typography>
            <TextField
              required
              autoComplete="off"
              name="country"
              value={addCustomer ? addCustomer?.country : ""}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                HandleChange(e)
              }
            />
          </div>
          <div className="dialogForm-field addchanelFields">
            <Typography variant="h5" className="dialogForm-field-label">
              {t("main.Post/Area_Code")}
            </Typography>
            <TextField
              required
              type="number"
              autoComplete="off"
              name="zipcode"
              InputProps={{
                inputProps: {
                  min: 5,
                  max: 6,
                },
              }}
              value={addCustomer ? addCustomer?.zipcode : ""}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                HandleChange(e)
              }
            />
          </div>
          <div className="dialogForm-field addchanelFields">
            <Typography variant="h5" className="dialogForm-field-label">
              {t("main.Phone_Number")} 1
            </Typography>
            <TextField
              required
              type="number"
              InputProps={{
                inputProps: {
                  min: 10,
                  max: 10,
                },
              }}
              autoComplete="off"
              name="phoneNumber1"
              value={addCustomer ? addCustomer?.phoneNumber1 : ""}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                HandleChange(e)
              }
            />
          </div>
          <div className="dialogForm-field addchanelFields">
            <Typography variant="h5" className="dialogForm-field-label">
              {t("main.Phone_Number")} 2
            </Typography>
            <TextField
              required
              type="number"
              InputProps={{
                inputProps: {
                  min: 10,
                  max: 10,
                },
              }}
              autoComplete="off"
              name="phoneNumber2"
              value={addCustomer ? addCustomer?.phoneNumber2 : ""}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                HandleChange(e)
              }
            />
          </div>
          <div className="dialogForm-field addchanelFields">
            <Typography variant="h5" className="dialogForm-field-label">
              {t("main.Phone_Number")} 3
            </Typography>
            <TextField
              required
              type="number"
              InputProps={{
                inputProps: {
                  min: 10,
                  max: 10,
                },
              }}
              autoComplete="off"
              name="phoneNumber3"
              value={addCustomer ? addCustomer?.phoneNumber3 : ""}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                HandleChange(e)
              }
            />
          </div>
          <div className="dialogForm-field addchanelFields">
            <Typography variant="h5" className="dialogForm-field-label">
              {t("main.Contact_Name")}
            </Typography>
            <TextField
              required
              autoComplete="off"
              name="contactName"
              value={addCustomer ? addCustomer?.contactName : ""}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                HandleChange(e)
              }
            />
          </div>
          <DialogActions sx={{ display: "flex" }}>
            <Button
              type="submit"
              size="medium"
              variant="contained"
              color="primary"
              sx={{ mt: 5 }}
              onClick={AddNewCustomer}
              disabled={isDisable}
            >
              {t("main.save")}
            </Button>
            <Button
              size="medium"
              variant="outlined"
              color="primary"
              sx={{ mt: 5 }}
              onClick={handleClose}
            >
              {t("main.cancel")}
            </Button>
          </DialogActions>
        </form>
      </div>
    </Dialog>
  );
}
